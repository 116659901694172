<template>
  <div style="display: flex;justify-content: space-between">


    <div style="width: 49.5%;">

      <!-- <div class="headers" style="display: flex">
        <el-input :placeholder="$t('cip.dc.KFDComission.name')" v-model="queryList2.name" class="new-sino-input"
          size="mini" style="width: 200px" />
        <el-button-group style="margin-left: 10px">
          <el-button size="mini" type="primary" icon="el-icon-search" @click="onLoad"></el-button>
          <el-button size="mini" icon="reset-refresh icon-refresh" @click="czFn"></el-button>
        </el-button-group>
      </div> -->

      <el-card >
        <head-layout head-title="源数据差异">
      </head-layout>
        <el-table :data="tableData" size="samll" border style="width: 100%;margin-top: 10px;" height="650">
        <el-table-column v-for="(item, index) in tableOptions" :key="index" :prop="item" :label="item" width="120">
        </el-table-column>

      </el-table>
      <div style="float: right;margin-top: 10px;margin-bottom: 10px;">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :currentPage="page.currentPage" :page-sizes="[10, 50, 100]" :page-size="page.pageSize"
          layout="total,sizes, prev, pager, next" :total="total" class="paging">
        </el-pagination>
      </div>

      </el-card>









    </div>


    <div style="width: 49.5%;">

      <!-- <div class="headers" style="display: flex">
        <el-input :placeholder="$t('cip.dc.KFDComission.name')" v-model="queryList2.name" class="new-sino-input"
          size="mini" style="width: 200px" />
        <el-button-group style="margin-left: 10px">
          <el-button size="mini" type="primary" icon="el-icon-search" @click="onLoad2"></el-button>
          <el-button size="mini" icon="reset-refresh icon-refresh" @click="czFn2"></el-button>
        </el-button-group>
      </div> -->


      <el-card>
        <head-layout head-title="目标数据差异">
      </head-layout>

      <el-table :data="tableData2" size="samll" border style="width: 100%;margin-top: 10px;" height="650">
        <el-table-column v-for="(item, index) in tableOptions2" :key="index" :prop="item" :label="item" width="120">
        </el-table-column>

      </el-table>
      <div style="float: right;margin-top: 10px;margin-bottom: 10px;">
        <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2"
          :currentPage="page2.currentPage" :page-sizes="[10, 50, 100]" :page-size="page2.pageSize"
          layout="total,sizes, prev, pager, next" :total="total2" class="paging">
        </el-pagination>
      </div>
      </el-card>




    </div>









  </div>
</template>

<script>
import {
  tokentDel,
  tokenInitcode,
  getCompareDiff,
  startDataCompare,
  delcompare,
  execDataCompare,
} from "@/api/dataAcquisition/index";

import { mapGetters } from "vuex";
import website from "@/config/website";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from "@/views/components/layout/form-layout";
import { PAGE_CONSTANT } from "@/util/pageConstantEnum";
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout,
  },
  data() {
    return {


      tableOptions: [],
      tableOptions2: [],
      page: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      },

      page2: {
        pageSize: PAGE_CONSTANT.TEN,
        currentPage: 1,
        total: 0,
      },
      tableLoading: false,
      tableLoading2: false,
      tableData: [],
      tableData2: [],
      total: 0,
      queryList: {
        query: {
          current: 1,
          size: 10,
        },
        name: "",
        datasetId: '',
        type: '1'
      },
      queryList2: {
        query: {
          current: 1,
          size: 10,
        },
        name: "",
        datasetId: '',
        type: '2'
      },
      loading: false,

    };
  },
  computed: {},
  methods: {



    onLoad() {
      this.loading = true;
      this.queryList.datasetId = this.$route.query.id
      getCompareDiff(this.queryList).then((res) => {


        if (res.data.dataList.length > 0) {
          this.tableOptions = Object.keys(res.data.dataList[0])
        }
        this.loading = false;
        this.tableData = res.data.dataList;
        this.total = res.data.page.totalSize;
      });
    },

    onLoad2(page, params = {}) {
      this.loading = true;
      this.queryList2.datasetId = this.$route.query.id
      getCompareDiff(this.queryList2).then((res) => {


        if (res.data.dataList.length > 0) {
          this.tableOptions = Object.keys(res.data.dataList[0])
        }
        this.loading = false;
        this.tableData2 = res.data.dataList;
        this.total2 = res.data.page.totalSize;
      });
    },










    czFn() {
      this.queryList.name = "";
      this.onLoad();
    },


    handleSizeChange(val) {
      this.page.pageSize = val;
      this.queryList.query.current = 1;
      this.queryList.query.size = val;
      this.onLoad();
    },
    handleCurrentChange(val) {
      this.page.currentPage = val;
      this.queryList.query.current = val;
      this.onLoad();
    },


    czFn2() {
      this.queryList2.name = "";
      this.onLoad2();
    },


    handleSizeChange2(val) {
      this.page2.pageSize = val;
      this.queryList2.query.current = 1;
      this.queryList2.query.size = val;
      this.onLoad2();
    },
    handleCurrentChange2(val) {
      this.page2.currentPage = val;
      this.queryList2.query.current = val;
      this.onLoad2();
    },




  },
  created() {
    this.onLoad();
    this.onLoad2();
  },
  mounted() { },
};
</script>
<style scoped>
.headers {
  background: #ffffff;
  padding: 14px 12px;
}

.headers ::v-deep .el-button {
  height: 28px;
}
::v-deep .el-button+.el-button {
  margin-left: 0px;
}
</style>



